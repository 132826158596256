import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Rank from "./rank";
import Filters from "./filters";
import axios from "axios";
import SendLog from "../services/SendLog.js";

const DivBody = styled.div`
  min-height: 90vh;
  width: 100%;
  @media (min-width: 820px) {
    display: flex;
  }
`;
const DivMain = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const wait = async (time) => {
  const promise = (x, time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(x);
      }, time);
    });
  };
  return await promise(null, time);
};

const Body = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState();
  const [currentFilter, setCurrentFilter] = useState({});

  useEffect(() => {
    //simulando a request
    axios
      .post(
        "https://rn3xfhamppsetddkod6vwc24lu0lhcek.lambda-url.us-east-1.on.aws/component-rank",
        {
          category: "filters",
        }
      )
      .then((response) => {
        setFilters(response.data.filters);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    SendLog();
    axios
      .post(
        "https://rn3xfhamppsetddkod6vwc24lu0lhcek.lambda-url.us-east-1.on.aws/component-rank",
        { options: currentFilter }
      )
      .then(async (response) => {
        // await console.log(response);
        setData(response["data"]);
      });

    wait(1000)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentFilter]);

  const selectFilter = (filter, value) => {
    let newFilter = {};
    newFilter[filter] = value;

    setCurrentFilter(newFilter);
  };

  return (
    <DivBody>
      <DivMain>
        <Filters
          filters={filters}
          onSelectFilter={selectFilter}
          setFilters={setFilters}
        ></Filters>
        <Rank loading={loading} data={data}></Rank>
      </DivMain>
    </DivBody>
  );
};

export default Body;
